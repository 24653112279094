import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

var body = document.querySelector('body')
I18n.locale = body ? body.dataset.locale : 'ru'

export default new VueI18n({
  locale: I18n.locale,
  messages: I18n.translations,
})
