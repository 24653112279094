import axios from 'axios'
import EventBus from '../event_bus'

axios.interceptors.response.use(response => {
  if(response.data.flash) {
    EventBus.$emit('success', response.data.flash, response.data)
  }
  if(response.data && response.data.meta && response.data.meta.notice) {
    EventBus.$emit('success', response.data.meta.notice, response.data)
  }
  return response
}, (error) => {
  if(error.response && error.response.data && error.response.data.alert) {
    EventBus.$emit('failure', error.response.data.alert, error.response)
  }
  return Promise.reject(error);
});
