import Vue from 'vue'
import Vuetify from 'vuetify'
import i18n from './i18n'
import ru from 'vuetify/es5/locale/ru'
import uk from 'vuetify/es5/locale/uk'
import en from 'vuetify/es5/locale/en'

// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/dist/vuetify.min.css'

import colors from 'vuetify/es5/util/colors'

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify, {
  icons: {
    'cancel': 'mdi-cancel',
    'save': 'mdi-content-save',
    'submit': 'mdi-send',
    'delete': 'mdi-delete-outline',
    'fill': 'mdi-textbox',
    'view': 'mdi-eye-outline',
    'edit': 'mdi-square-edit-outline',
    'remind': 'mdi-bell-ring-outline',
    'sync': 'mdi-sync',

    'received_data': 'mdi-inbox-multiple',
    'settings': 'mdi-cogs',
    'deadlines': 'mdi-timelapse',
    'profile': 'mdi-clipboard-account',
    'archive': 'mdi-archive',

    'routine-coi': 'mdi-set-none',
    'routine-gift': 'mdi-gift-outline',
    'routine-analysis': 'mdi-find-replace',
    'routine-log': 'mdi-clock-outline',
    'publicly_available': 'mdi-hand',
    'by_invitation': 'mdi-email-lock',
    'routine-contributor': 'mdi-human-greeting',
    'routine-start': 'mdi-play',
    'routine-finish': 'mdi-stop',
    'routine-template': 'mdi-bookmark',

    'form-information': 'mdi-information-outline',
    'form-statement': 'mdi-bullhorn-outline',
    'form-company_relations': 'mdi-briefcase-outline',
    'form-coworkers_relations': 'mdi-tie',

    'entry-analysis': 'mdi-find-replace',
    'entry-counterparties': 'mdi-account-box-multiple',
    'entry-form': 'mdi-ballot-outline',
    'entry-comments': 'mdi-comment-text-multiple-outline',
    'entry-log': 'mdi-clock-outline',

    'risk-indicators': 'mdi-pulse',
    'risk-absent': 'mdi-radiobox-blank',
    'risk-reconciled': 'mdi-check',
    'risk-unresolved': 'mdi-shield-outline',
    'risk-resolving': 'mdi-shield-half-full',
    'risk-resolved': 'mdi-shield-check',
    // 'risk-resolved': 'mdi-verified',
  },
  lang: {
    locales: { ru, uk, en },
    current: i18n.locale
  },
  theme: {
    primary: colors.indigo.lighten2,
  }
})
