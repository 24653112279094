<template>
  <v-app id="login" class="">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <!-- <v-radio-group dark v-model="locale">
              <v-layout row justify-space-around>
                <v-flex xs2 v-for="item, key in locales" :key="key">
                  <v-radio :label="item.label" :value="item.locale"></v-radio>
                </v-flex>
              </v-layout>
            </v-radio-group> -->
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <div class="flex mb-2 subheading grey--text" v-html="heading"></div>
                </div>
                <v-form @submit.prevent="onSend">
                  <v-text-field append-icon="person" name="login" label="Email" type="text" v-model="model.email" id="signin_email"></v-text-field>
                  <!-- <v-text-field append-icon="lock" name="password" :label="$t('helpers.label.user.password')" id="password" type="password" v-model="model.password"></v-text-field> -->
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="url" color="primary" :href="url">{{ $t('actions.login') }}</v-btn>
                <v-btn v-else :disabled="disabled" color="primary" @click="onSend" :loading="loading">{{ $t('actions.send_login_link') }}</v-btn>
                <!-- <v-btn color="primary" @click="login" :loading="loading">{{ $t('actions.login') }}</v-btn> -->
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.text }}
      <v-btn dark flat @click="snackbar.show = false">{{ $t('actions.close') }}</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import axios from 'axios'
import BaseLayout from 'applications/base/layout'
export default {
  extends: BaseLayout,
  data: () => ({
    loading: false,
    locale: 'ru',
    locales: [
      { locale: 'ru', label: 'Рус' },
      { locale: 'uk', label: 'Укр' },
      { locale: 'en', label: 'Eng' }
    ],
    model: {
      email: '',
      password: ''
    },
    params: null,
    disabled: false,
    url: '',
  }),
  computed: {
    heading() {
      return this.$t('app.login').replace(/([^>])\n/g, '$1<br/>');
    }
  },
  mounted() {
    this.params = new URLSearchParams(window.location.search)
    this.model.email = this.params.get('email')
  },
  methods: {
    onSend() {
      if(this.disabled) {
        return
      }
      this.loading = true
      axios.post('/auth/login', {
        email: this.model.email,
        r: this.params.get('r'),
        redirect_url: this.params.get('redirect_url'),
      })
        .then(response => {
          // this.url = response.data.url
          this.loading = false
          this.disabled = true
          setTimeout(() => {
            this.disabled = false
          }, 60 * 1000)
        })
        .catch(error => {
          this.loading = false
        })
    }
  }

};
</script>
<style scoped lang="css">
  #login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
  }
</style>
