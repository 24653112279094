<template>
  <v-app>
    <e-navigation></e-navigation>
    <v-content class="pb-5">
      <v-container class="pb-5">
        <router-view></router-view>
      </v-container>
    </v-content>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{ snackbar.text }}

      <v-btn dark flat @click="snackbar.show = false">{{ $t('actions.close') }}</v-btn>
    </v-snackbar>
    <e-loading v-model="loadingScreen"></e-loading>
  </v-app>
</template>

<script>
import EventBus from 'config/event_bus'
import eLoading from 'components/dialogs/Loading'

export default {
  components: {
    eLoading,
  },
  props: {
    logo: String,
    logoFull: String,
  },
  data() {
    return {
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      loadingScreen: false,
    }
  },
  created() {
    if(this.$store) {
      this.$store.dispatch('loadUI')
    }
  },
  mounted() {
    EventBus.$on('failure', (flash) => {
      this.snackbar = {
        text: flash,
        show: true,
        color: 'error'
      }
    })
    EventBus.$on('success', (flash) => {
      this.snackbar = {
        text: flash,
        show: true,
        color: 'success'
      }
    })
    EventBus.$on('loading', () => {
      this.loadingScreen = true
    })
    EventBus.$on('loaded', () => {
      this.loadingScreen = false
    })
  }
}
</script>
