import axios from 'axios'

axios.interceptors.request.use(config => {
  ahoy.track('request', { url: config.url })
  return config;
});

axios.interceptors.response.use(response => {
  ahoy.track('success', { url: response.config.url, status: response.status })
  return response;
}, function (error) {
    if(error.response) {
      ahoy.track('error', { url: error.response.config.url, status: error.response.status })
    }
  return Promise.reject(error);
});
