<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t('flash.loading') }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean
  },
  data() {
    return {
      dialog: false
    }
  },
  watch: {
    value(value) {
      this.dialog = value
    }
  }
}
</script>

<style>

</style>
