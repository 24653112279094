import Vue from 'vue/dist/vue.esm'

import 'config/vuetify'
import 'config/resource'
import 'config/interceptors/active_requests_counter'
import 'config/interceptors/tracker'
import 'config/interceptors/alert'
import i18n from 'config/i18n'
import VuetifyApplication from './layout'

Vue.config.productionTip = false
ahoy.trackAll();

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#app',
    i18n,
    components: {
      VuetifyApplication
    }
  })
})
