import axios from 'axios'

window.activeAxiosRequests = 0;

axios.interceptors.request.use(config => {
  window.activeAxiosRequests += 1;
  return config;
}, function (error) {
  window.activeAxiosRequests -= 1;
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  window.activeAxiosRequests -= 1;
  return response;
}, function (error) {
  window.activeAxiosRequests -= 1;
  return Promise.reject(error);
});
